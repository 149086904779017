<template>
    <CompTable title="查阅明细" :columns="columns" :table-api="tableApi"></CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            tableApi: `/gateway/api/symanage/pc/report/listReportViewDetail?itemId=${this.$core.getUrlParam("i")}&orgCodeSelf=${this.$core.getUrlParam("c")}`,
            columns: [
                {
                    title: "机构",
                    minWidth: 300,
                    align: "center",
                    key: "orgName",
                },
                {
                    title: "姓名",
                    minWidth: 150,
                    align: "center",
                    key: "userName",
                },
                {
                    title: "账号",
                    minWidth: 200,
                    align: "center",
                    key: "account",
                },
                {
                    title: "查阅次数",
                    minWidth: 150,
                    align: "center",
                    key: "view",
                },
                {
                    title: "查阅时间",
                    minWidth: 210,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.viewDate ? this.$core.formatDate(new Date(params.row.viewDate), "yyyy/MM/dd hh:mm:ss") : "-")
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
